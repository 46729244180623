<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>水站进货明细表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 20px 20px 0;
        white-space: nowrap;
      "
    >
      <el-row>
        <el-col :span="9" style="display: flex; flex-wrap: nowrap">
          <span>创建时间：</span>
          <el-date-picker
            type="date"
            v-model="creatStartDate"
            style="width: 35%"
            :picker-options="creatOpt1"
          ></el-date-picker>
          <span style="margin: 0 5px">-</span>
          <el-date-picker
            type="date"
            v-model="creatEndDate"
            style="width: 35%"
            :picker-options="creatOpt2"
          ></el-date-picker>
        </el-col>
        <el-col :span="9" style="display: flex; flex-wrap: nowrap">
          <span>完成时间：</span>
          <el-date-picker
            type="date"
            v-model="finishStartDate"
            style="width: 35%"
            :picker-options="finishOpt1"
          ></el-date-picker>
          <span style="margin: 0 5px">-</span>
          <el-date-picker
            type="date"
            v-model="finishEndDate"
            style="width: 35%"
            :picker-options="finishOpt2"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row style="margin-top: 10px">
        <el-col :span="12">
          <div>
            <span>选择水站：</span>
            <el-select
              v-model="option1"
              clearable
              filterable
              placeholder="选择水站"
              style="width: 60%"
            >
              <el-option
                v-for="item in option1es"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <span>配送司机：</span>
            <el-select
              v-model="option2"
              clearable
              filterable
              placeholder="配送司机"
              style="width: 60%"
            >
              <el-option
                v-for="item in option2es"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      style="
        padding: 10px 20px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
      "
    >
      <el-input
        v-model="QueryContent"
        style="width: 16%; margin-right: 5px"
        placeholder="输入品牌名称"
      ></el-input>
      <el-button type="success" size="mini">
        <div style="display: flex; align-items: center; font-size: 14px">
          <img
            style="width: 16px; height: 16px"
            :src="require('@/assets/iconImg/logo.png')"
          />
          <span>查询</span>
        </div>
      </el-button>
      <el-button type="success" size="mini">
        <div style="display: flex; align-items: center; font-size: 14px">
          <img
            style="width: 16px; height: 16px"
            :src="require('@/assets/iconImg/logo.png')"
          />
          <span>清楚</span>
        </div>
      </el-button>
      <el-button type="success" size="mini">
        <div style="display: flex; align-items: center; font-size: 14px">
          <img
            style="width: 16px; height: 16px"
            :src="require('@/assets/iconImg/logo.png')"
          />
          <span>导出报表</span>
        </div>
      </el-button>
    </div>
    <div style="padding: 20px">
      <table class="table">
        <tr>
          <th
            v-for="(item, index) in tableth"
            :key="item.id"
            @click="sortClick(item.value)"
            :class="isSortClass(item.title)"
            class="unselectable"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div>{{ item.title }}</div>
              <div
                v-if="
                  item.title == '创建时间' ||
                  item.title == '完成时间' ||
                  item.title == '规格' ||
                  item.title == '数量' ||
                  item.title == '进货价' ||
                  item.title == '金额'
                "
              >
                <img
                  style="width: 20px; height: 20px; padding-top: 3px"
                  :src="require('@/assets/iconImg/sort/排序.png')"
                  v-if="sortIcon[index] == 0"
                />
                <img
                  style="width: 16px; height: 16px; padding-top: 3px"
                  :src="require('@/assets/iconImg/sort/排序up.png')"
                  v-else-if="sortIcon[index] == 1"
                />
                <img
                  style="width: 16px; height: 16px; padding-top: 3px"
                  :src="require('@/assets/iconImg/sort/排序down.png')"
                  v-else-if="sortIcon[index] == -1"
                />
              </div>
            </div>
          </th>
        </tr>
        <tr v-for="(item) in newTabletd" :key="item.id">
          <td>{{ item.creatDate }}</td>
          <td>{{ item.finishDate }}</td>
          <td>{{ item.serial }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.driver }}</td>
          <td>{{ item.courier }}</td>
          <td>{{ item.product }}</td>
          <td>{{ item.specification }}</td>
          <td>{{ item.number }}</td>
          <td>{{ item.purchasingCost }}</td>
          <td>{{ item.money }}</td>
          <td
            style="
              padding: 10px 20px;
              border: 0;
              border-bottom: 1px solid #d0cfd1;
              border-right: 1px solid #d0cfd1;
            "
          >
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span style="margin: 0 10px"></span>
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
          </td>
        </tr>
        <tr v-if="this.tabletd.length == 0">
          <td :colspan="12" style="color: #666">没有数据</td>
        </tr>
        <tr>
          <td :colspan="7">合计</td>
          <td></td>
          <td>{{ totalNum }}</td>
          <td>{{ totalCost }}</td>
          <td>{{ totalMoney }}</td>
          <td></td>
        </tr>
      </table>
      <div class="tableBottom">
        <span v-if="isResult" style=""
          >显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >
        <span v-if="!isResult" style=""
          >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      creatStartDate: "",
      creatEndDate: "",
      creatOpt1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      creatOpt2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.creatStartDate.getTime()
          );
        },
      },
      finishStartDate: "",
      finishEndDate: "",
      finishOpt1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      finishOpt2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.finishStartDate.getTime()
          );
        },
      },

      QueryContent: "",
      option1: "",
      option1es: [],
      option2: "",
      option2es: [],
      option3: "",
      option3es: [],

      tableth: [
        { id: 1, title: "创建时间", value: "creatDate" },
        { id: 2, title: "完成时间", value: "finishDate" },
        { id: 3, title: "订单编号", value: "serial" },
        { id: 4, title: "水站名称", value: "name" },
        { id: 5, title: "配送司机", value: "driver" },
        { id: 6, title: "配送员", value: "courier" },
        { id: 7, title: "商品名称", value: "product" },
        { id: 8, title: "规格", value: "specification" },
        { id: 9, title: "数量", value: "number" },
        { id: 10, title: "进货价", value: "purchasingCost" },
        { id: 11, title: "金额", value: "money" },
        { id: 12, title: "操作", value: "dsc" },
      ],
      tabletd: [
        {
          id: 1,
          creatDate: "2023-05-06",
          finishDate: "2022-07-21",
          serial: "",
          name: "",
          driver: "",
          courier: "",
          product: "",
          specification: 0,
          number: 3,
          purchasingCost: 66,
          money: 55,
        },
        {
          id: 2,
          creatDate: "2023-05-07",
          finishDate: "2022-07-15",
          serial: "",
          name: "",
          driver: "",
          courier: "",
          product: "",
          specification: 1,
          number: 1,
          purchasingCost: 44,
          money: 88,
        },
        {
          id: 3,
          creatDate: "2023-05-03",
          finishDate: "2022-07-11",
          serial: "",
          name: "",
          driver: "",
          courier: "",
          product: "",
          specification: 2,
          number: 5,
          purchasingCost: 22,
          money: 22,
        },
        {
          id: 4,
          creatDate: "2023-05-01",
          finishDate: "2022-07-05",
          serial: "",
          name: "",
          driver: "",
          courier: "",
          product: "",
          specification: 3,
          number: 3,
          purchasingCost: 101,
          money: 55,
        },
      ],
      isResult: false,
      tableFinshNum: 0,

      sortIcon: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      sortKey: "",
      sortDirection: 1,
    };
  },
  mounted() {
    if (this.tabletd.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tabletd.length;
    }
  },
  computed: {
    totalNum() {
      if (this.tabletd.length === 0) {
        return " ";
      } else {
        return this.tabletd.reduce((acc, cur) => acc + cur.number, 0);
      }
    },
    totalCost() {
      if (this.tabletd.length === 0) {
        return " ";
      } else {
        return this.tabletd.reduce((acc, cur) => acc + cur.purchasingCost, 0);
      }
    },
    totalMoney() {
      if (this.tabletd.length === 0) {
        return " ";
      } else {
        return this.tabletd.reduce((acc, cur) => acc + cur.money, 0);
      }
    },
    newTabletd() {
      const tabletd = [...this.tabletd];
      if (this.sortKey) {
        return tabletd.sort((a, b) => {
          const sortVal = a[this.sortKey] > b[this.sortKey] ? 1 : -1;
          return this.sortDirection * sortVal;
        });
      }
      return tabletd;
    },
  },
  methods: {
    reload() {
      location.reload();
    },
    handleEdit() {},
    handleDelete() {},
    isSortClass(val) {
      return val === "数量" ||
        val === "创建时间" ||
        val === "完成时间" ||
        val === "规格" ||
        val === "进货价" ||
        val === "金额"
        ? "pointer"
        : "noPointer";
    },
    sortClick(key) {
      if (this.sortKey === key) {
        this.sortDirection = -this.sortDirection;
        this.tableth.map((item, index) => {
          if (item.value == key) {
            this.sortIcon[index] = -1;
            this.sortKey = item.value
          }
        });
      } else {
        this.sortKey = key;
        this.sortDirection = 1;
        this.tableth.map((item, index) => {
          this.sortIcon[index] = 0;
          if (item.value == key) {
            this.sortIcon[index] = 1;
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  text-align: center;
  th {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }
  td {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }
}

.pointer {
  cursor: pointer;
}
.noPointer {
  pointer-events: none;
}
.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
